<template>
  <b-modal v-model="showImageModal" title="File Preview" @hidden="modalHidden" >
    <template v-if="isImage">
      <!-- Display image -->
      <img :src="fileContent" alt="File Preview" style="max-width: 100%; max-height: 400px;" />
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    fileContent: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true
    },
    showImageModal: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {

    };
  },
  methods: {
    modalHidden() {
      this.$emit('close'); // Emit a close event to notify the parent component
    },
    isImage() {
      return /^data:image\/(jpeg|png|gif|jpg);base64,/.test(this.fileContent);
    },

  }
};
</script>
